import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import 'moment/locale/es';
import 'moment/locale/pt';

import NewsItemComponent from '../components/NewsItem';
import PageTitle from '../components/PageTitle';
import PageHeader from '../components/PageHeader';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { theme } from '../constants/theme';

export const query = graphql`
  query Noticias($id: String, $lang: String) {
    prismic {
      allNews_landings(id: $id) {
        edges {
          node {
            title
            _meta {
              lang
            }
          }
        }
      }

      allNewss(lang: $lang) {
        edges {
          node {
            title
            listing_image
            date
            featured
            _meta {
              lang
              uid
              type
            }
          }
        }
      }
    }
  }
`;

const NewsLandingPage = ({ data, location }) => {
  const page = data.prismic.allNews_landings.edges[0];

  if (!page || !page.node) {
    return null;
  }
  const {
    title,
    _meta: { lang },
  } = page.node;

  const news = data.prismic.allNewss.edges;

  return (
    <Layout lang={lang} location={location}>
      <SEO title="Home" />
      <PageHeader>
        <PageTitle mainTitleText={title} />
      </PageHeader>
      <Content>
        <NewsContent>
          {news && news.map((newsItem) => <NewsItemComponent newsItem={newsItem} />)}
        </NewsContent>
      </Content>
    </Layout>
  );
};

const NewsContent = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-evenly;
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 118px 40px 110px 40px;


  @media ${theme.breakpoints.size.xl} {
    margin: 80px 40px 120px 40px;
  }

  @media ${theme.breakpoints.size.l} {
    margin: 78px 20px 120px 20px;
  }
  @media ${theme.breakpoints.size.m} {
    margin: 78px 20px 120px 20px;
  }
  @media ${theme.breakpoints.size.s} {
    margin: 50px 20px 62px 20px;
  }
`;

export default NewsLandingPage;
