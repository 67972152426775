import React from 'react';
import { Link } from 'gatsby';
import { Date } from 'prismic-reactjs';
import moment from 'moment';
import styled from 'styled-components';
import { linkResolver } from '../utils/linkResolver';
import { theme } from '../constants/theme';

const NewsItemComponent = ({ newsItem }) => {
  if (!newsItem || !newsItem.node) {
    return null;
  }
  const date = Date(newsItem.node.date);

  const momentFormatString = 'DD MMMM YYYY';

  if (newsItem.node._meta.lang === 'es-mx') {
    moment.locale('es');
  } else if (newsItem.node._meta.lang === 'pr-br') {
    moment.locale('pt');
  } else {
    moment.locale('en');
  }

  return (
    <NewsItem featured={newsItem.node.featured}>
      <Link to={linkResolver(newsItem.node._meta)}>
        <NewsItemImage src={newsItem.node.listing_image && newsItem.node.listing_image.url} />
        <NewsDate
          featured={newsItem.node.featured}
        >
          {moment(date).format(momentFormatString)}
        </NewsDate>
        <h3>{newsItem.node.title}</h3>
      </Link>
    </NewsItem>
  );
};

const NewsItemImage = styled.img`
  display: block;
  width: 100%;
`;

const NewsItem = styled.li`
  margin: 0px 40px 108px 40px;

  width: ${(props) => (props.featured ? 'calc(67% - 80px)' : 'calc(33% - 80px)')};

  @media ${theme.breakpoints.size.xl} {
    margin: 0px 40px 68px 40px;
  }

  @media ${theme.breakpoints.size.l} {
    width: ${(props) => (props.featured ? 'calc(67% - 40px)' : 'calc(33% - 40px)')};
    margin: 0px 20px 70px 20px;
  }

  @media ${theme.breakpoints.size.m} {
    width: ${(props) => (props.featured ? '100%' : 'calc(50% - 40px)')};
  }

  @media ${theme.breakpoints.size.s} {
    width: 100%;
    margin: 0px 0px 66px 0px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    h3 {
      color: #F50000
    }
  }

  @media (hover: none) {
    &:hover {
      h3 {
        color: black;
      }
    }
  }

  h3 {
    margin: 0;
    text-align: center;
    font-size: ${(props) => (props.featured ? '48px' : '28px')};
    font-weight: 400;
    font-size: ${(props) => (props.featured ? '48px' : '28px')};
    color: black;


    @media ${theme.breakpoints.size.xl} {
      font-size: ${(props) => (props.featured ? '35px' : '22px')};
      line-height: ${(props) => (props.featured ? '43px' : '29px')};
    }

    @media ${theme.breakpoints.size.s} {
      font-size: 22px;
      line-height: 29px;
    }
  }
`;

const NewsDate = styled.p`
  color: ${theme.colors.grey};
  font-size: 16px;
  margin: ${(props) => (props.featured ? '25px auto 10px;' : '25px auto 10px;')};
  text-align: center;

  @media ${theme.breakpoints.size.xl} {
    margin: 20px auto 10px;
  }

  @media ${theme.breakpoints.size.m} {
    margin: ${(props) => (props.featured ? '20px auto 10px;' : '20px auto 10px;')};
  }
`;

export default NewsItemComponent;
